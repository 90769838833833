export default class Cookies {
	constructor() {
		let cookiesAcceptedAt = window.localStorage.getItem('hmvst_cookies_accepted_at');
		if (cookiesAcceptedAt === null) {
			window.localStorage.removeItem('hmvst_cookies_accepted_at');
			this.cookieBanner = document.querySelector('.cookie-banner');
			this.showCookieBanner();
		}

		const acceptCookiesButtons = document.querySelectorAll('button[data-action="cookie-banner#accept"]');
		acceptCookiesButtons.forEach((button) => {
			button.addEventListener('click', (event) => {
				event.preventDefault();
				this.acceptCookies();
			});
		});
	}

	acceptCookies() {
		window.localStorage.setItem("hmvst_cookies_accepted_at", new Date().toISOString());
		this.hideCookieBanner();
	}

	showCookieBanner() {
		this.cookieBanner.style.display = 'block';
	}

	hideCookieBanner() {
		this.cookieBanner.style.display = 'none';
	}
}